// Header principal
.header-full {
  position: fixed;
  z-index: $z_index_header;
  top: 0;
  left: 0;
  float: left;
  width: 100%;
  background: #FFF;
  padding-top:25px;
  padding-bottom:20px;
    box-shadow: 0 1px 3px rgba(0,0,0,.26);
  transition: .5s ease;
  
  // MOBILE
  @include mq(s) {
    padding-top: 40px;
    padding-bottom: 10px;
    text-align: center;
  }
}

// Conteúdo (Container)
.header-full .container {
  position: static;
}
.header-content {
  position: relative;
  padding: 0 10px;

  // MOBILE
  @include mq(s) {
    position: static;
  }
}

// Logo
.header-full .header-logo {
  display: block;
  float: left;
  max-width: 300px;
  
  // TABLET
  @include mq(m) {     
    max-width: 180px;
    margin-top: 5px;
  }
  
  // MOBILE
  @include mq(s) {  
    float: none;
    display: inline-block;
    vertical-align: top;
    max-width: 160px;
  }
  
  // Logo branco
  &.white-logo {
    display: none;
  }
}

// Direita
.header-full .header-direita {
  float: right;
  padding-top: 5px;

  // MOBILE
  @include mq(s) {
    height: 0px;
  }
}

// Botões
.header-full .header-botoes {
  float: right;
  text-align: right;
  margin-left: 50px;
  
  // MOBILE
  @include mq(s) {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    display: none;
  }
  
  .btn {
    margin-left: 10px;
    
    // MOBILE
    @include mq(s) {
      font-size: 12px;
      margin: 5px;
      padding: 10px 15px;
    }
  }
}

// Telefones
.header-full .header-telefones {
  text-align: right;
  background: $cor_primaria;
  float: left;
  padding: 5px 20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  position: absolute;
  top: -25px;
  right: 0;
  
  // MOBILE
  @include mq(s) {
    position: absolute;
    top: 0 !important;
    left: 0;
    padding: 3px;
    background: #DDD;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  
  // Item
  a {
    display: inline-block;      
    margin-left: 20px;    
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
    color: #FFF;

    // MOBILE
    @include mq(s) {
      color: #333;
    }

    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
    
    // MOBILE
    @include mq(s) {
      font-size: 14px;
      margin: 0 10px;
    }
    
    i {
      margin-right: 5px;
      color: #FFF;

      // MOBILE
      @include mq(s) {
        color: $cor_primaria;
      }
    }
  }
}

// ---------------------------- HOME ---------------------------- //
body.home-full {
  padding-top: 0px;
}

// Header
.home-full .header-full:not(.active) {
  background: none;
  background-image: linear-gradient(to bottom, rgba(#000, .5), rgba(#000, 0));
  box-shadow: none;
  padding-top: 40px;
  padding-bottom: 40px;

  // MOBILE
  @include mq(s) {
    padding-top: 45px;
  }
}

// Logo
.home-full .header-full:not(.active) .header-logo {
  // Logo COLORIDO
  &.colored-logo {
    display: none;
  }

  // Logo BRANCO
  &.white-logo {
    display: block;
    // MOBILE
    @include mq(s) {
      display: inline-block;
    }
  }
}

// Telefones
.home-full .header-full:not(.active) .header-telefones {
  top: -40px;

  // MOBILE
  @include mq(s) {
    padding-top: 5px;
    padding-bottom: 5px;
    background: rgba(#FFF, .1);

    i {
      color: #FFF;
    }

  }
}
.home-full .header-full:not(.active) .header-telefones a {
  color: #FFF;
}

// Botão
.home-full .header-full:not(.active) .outline {
  border: 1px solid #FFF;
  color: #FFF;
  
  &:hover, &:focus, &:active, &.active {
    background: #FFF;
    color: $cor_primaria;
  }
}

// Menu lateral
.home-full .header-full:not(.active) .lateral .fdx-menu-1-level > li > a {
  color: #FFF;
  
  &:hover {
    color: #FFF;
  }
  
  &:before {
    background: #FFF;
  }
}

// Botão menu mobile
.home-full .header-full:not(.active) .menu-btn-mobile {
  border-color: #FFF;
  
  &::before, &::after {
    background: #FFF;
  }
}

// Slide
.home-full .slide-item .slide-image { 
  height: 100vh;
  min-height: 600px; 
  
  // TABLET
  @include mq(m) {
    height: 90vh;
  }
  
  // MOBILE
  @include mq(s) {
    height: 80vh;
    min-height: 400px; 
  }
}
.home-full .slide-item .slide-content { 
  padding-top: 100px;

  // MOBILE
  @include mq(s) {
    padding-top: 50px;
  }
}
