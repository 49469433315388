// Bloco serviço
.servico {
  position: relative;
  overflow: hidden;
  max-width: 500px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  margin-bottom: 20px;
  
  // Foto
  figure img {
    transform: scale(1.2);
    transition: .5s ease-out;
  }
  &:hover figure img {
    transform: scale(1);
  }
  
  // Máscara
  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, .3);
    transition: .3s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &::before, &::after {
      content: '';
      display: block;
      width: 100px;
      height: 1px;
      background: #FFF;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      transition: .3s ease-out;
      opacity: 0;
    }
    &::before {
      top: 95px;
    }
    &::after {
      bottom: 50px;
    }
  }
  &:hover span {
    background: rgba(#000, .6);
  }

  &:hover span::before {
    transform: translate(0,50px);
    opacity: 1;

    // MOBILE
    @include mq(s) {
      transform: translate(0,15px);
    }
  }
  &:hover span::after {
    transform: translate(0,-50px);
    opacity: 1;
  }
  // Título
  h2 {
    font-size: 20px;
    line-height: 1.2;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-shadow: 2px 2px 2px rgba(#000, .3);
    margin-top: 40px;
    margin-bottom: 15px;
    
    // MOBILE
    @include mq(s) {
      margin-top: 0px;
      font-size: 18px;
    }
  }
  
  // Botão
  .btn {
    opacity: 0;
    transform: scale(.5);
    
    // MOBILE
    @include mq(s) {
      opacity: 1;
      transform: scale(1);
    }
  }
  &:hover .btn {
    opacity: 1;
    transform: scale(1);
  }
}

// Carrosel
.carrosel-servicos {
  //margin-top: px;

  // Bloco serviço
  .servico {
    display: block;
    width: 100%;
    margin: 5px 10px;
  }
}

//secao home
.servicos-home {
  padding-top: 20px;

}

// Blocos página
.servicos-blocos-pag {

  // Bloco
  > a {
    background: rgba(#000, .05);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    margin-top: 3px;
    margin-bottom: 30px;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    // MOBILE
    @include mq(s) {
      margin-bottom: 20px;
    }

    &:last-child {
      // MOBILE
      @include mq(s) {
        margin-bottom: 30px;
      }
    }

    &:hover {
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }

    span {
      font-weight: bold;
      color: $cor_fonte_escura;
      transition: .3s ease-out;
    }
    &:hover span {
      color: $cor_primaria;
    }
  }
}
