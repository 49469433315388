// Banner do topo
.institucional-banner {
  position: relative;
	float: left;
	width: 100%;
	height: 200px;
	background-color: #DDD;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

  &.banner-titulo {
    height: auto;
    padding: 50px 0;

    // MOBILE
    @include mq(s) {
      padding: 30px 0;
    }
  }

  h1 {
    font-size: 32px;
    line-height: 1.2;
    font-weight: bold;
    color: #FFF;
    text-align: center;

    // MOBILE
    @include mq(s) {
      font-size: 20px;
    }
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(#333, .6);
  }

	// TABLET
	@include mq(m) {
		height: 150px;
	}

	// MOBILE
	@include mq(s) {
		height: 100px;
	}
}

/* Segura o texto das páginas instituicionais para evitar que fiquem com 100% de largura e prejudique a leitura */
.segura-texto {
	// DESKTOP
	@include mq(l) {
		float: none;
		margin-left: auto;
		margin-right: auto;
	}
}
